<template>
  <section class="about" style="background: #FFF;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner-s"
      >
        <v-layout
          class="hidden-sm-and-down"
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
      <v-container  grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="tnf-partner-div">
            <h2>Partner</h2>
            <h4>合作伙伴</h4>
            <h5> </h5>
            <v-layout wrap>
              <v-flex
                  v-for="(link, i) in info.links"
                  :key="i"
                  xs12 sm12 md6 lg3 mb-4 pa-2
              >
                <v-img
                  v-if="link.logo"
                  :src="link.logo"
                  height="60"
                  position="center"
                  aspect-ratio="1"
                  contain
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <h1 class="tnf-large-text">PARTNER</h1>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth,
    tabSubMenu: null,
    subMenu: [
      { key: 'about', name: '公司简介' },
      { key: 'story', name: '品牌故事' },
      { key: 'history', name: '发展历程' },
      { key: 'honor', name: '企业荣誉' },
      { key: 'assistant', name: '门窗管家' },
      { key: 'partner', name: '合作伙伴' }
    ]
  }),
  created () {
    // this.getAbout()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.link) {
        links = this.$store.getters.companyInfo.link.filter(ele => ele.type === 1)
      }
      return {
        links: links,
        banners: banners
      }
    }
  },
  methods: {
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.about = response.data
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/partner.css';
</style>
